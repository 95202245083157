import xss from 'xss';

// Requires xss packages to be installed in project that is using this helper
export function getXssFilter() {
  const options = {
      whiteList: {
          style: [], 
          b:[],
          i:[],
          p: ["style"],
          span: ["style"],
          a: ["href", "style", "target", "rel"],
          li: ["style", "class"],
          ul: ["style"],
          ol: ["style"],
          table: ["style", "border"],
          caption: ["style"],
          tbody: ["style"],
          th: ["style"],
          tr: ["style"],
          td: ["style"],
          img: ["style", "src", "alt", "width", "height"],
          em: [],
          strong: [],
          br: [],
          h1: ["style"],
          h2: ["style"],
          h3: ["style"],
          h4: ["style"],
          h5: ["style"],
          h6: ["style"],
          sup: [],
          sub: [],
          blockquote: [],
          div: [],
          pre: [],
          code: [],
          center: [],
          iframe: ["src", "width", "height", "frameborder", "allowfullscreen", "data-mce-fragment"],
          u: [],
          s: [],
          hr: []
      },
      css: {
          whiteList: {}
      }
  }

  options.css.whiteList['text-align'] = true;
  options.css.whiteList['text-decoration'] = true;
  options.css.whiteList['padding-left'] = true;
  options.css.whiteList['border-collapse'] = true;
  options.css.whiteList['width'] = true;
  options.css.whiteList['height'] = true;
  options.css.whiteList['margin'] = true;
  options.css.whiteList['margin-left'] = true;
  options.css.whiteList['margin-right'] = true;
  options.css.whiteList['margin-top'] = true;
  options.css.whiteList['margin-bottom'] = true;
  options.css.whiteList['float'] = true;
  options.css.whiteList['display'] = true;
  options.css.whiteList['max-height'] = true;
  options.css.whiteList['max-width'] = true;
  options.css.whiteList['color'] = true;
  options.css.whiteList['background-color'] = true;

  return new xss.FilterXSS(options);
}
