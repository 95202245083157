import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import setupApp from '../helpers/setupCheckoutApp';
import setupKioskConsole from '../helpers/setupKioskConsole';
import App from './App';
import '../helpers/embedBrowserAutofiller';
import { initConfigClient } from '@b/helpers/configClient';

let store = null;
let router = null;
let persistor = null;

// loading on the persist gate can be any component -- probably unnecessary for us
let renderWrapper = async (
    rootElement,
    apiUrl,
    listingSlug,
    listingConfig = {},
    eventId = 0,
    listingId = 0,
    errorHandler,
    language = {
        locale: 'en_US', defaultServerLocale: 'en_US', translations: {}, languages: [], jsLocale: 'en-US',
        defaultJsLocale: 'en-US'
    },
    brandName = 'ShowClix',
    forCMS = false,
    listingInfo,
    alwaysShowHiddenLevels = false,
) => {
    if (listingInfo.configClient && listingInfo.configClient.configCatSdkKey) {
        await initConfigClient(
          listingInfo.configClient.configCatSdkKey,
          listingInfo.configClient.context,
          listingInfo.configClient.leapGA4Code
        );
    }

    /**
     * Make sure this only happens once.  The CMS calls
     * this function multiple times as the user makes changes
     * to the checkout configuration, and we don't want the store
     * to get reset every time the user clicks a button.
     */
    if (!store) {
        let persistSuffix = ':patrontech:listing';
        if (listingId) {
            persistSuffix += `:${listingId}`;
        }
        const setupResult = setupApp(brandName, null, persistSuffix);
        store = setupResult.store;
        router = setupResult.router;
        persistor  = setupResult.persistor;
        setupKioskConsole(store);
    }

    // php json encodes empty array to array instead of to object
    if (Array.isArray(listingConfig)) {
        listingConfig = {};
    }

    render(
        <Provider store={store}>
            {/*@ts-ignore*/}
            <PersistGate loading={null} persistor={persistor}>
                <App
                    router={router}
                    store={store}
                    apiUrl={apiUrl}
                    listingSlug={listingSlug}
                    listingConfig={listingConfig}
                    listingInfo={listingInfo}
                    eventId={eventId}
                    listingId={listingId}
                    errorHandler={errorHandler}
                    language={language}
                    brandName={brandName}
                    alwaysShowHiddenLevels = {alwaysShowHiddenLevels}
                />
            </PersistGate>
        </Provider>,
        rootElement
    )
}

export default renderWrapper;
